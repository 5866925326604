import { countDeliveryDistance, countDeliveryPrice, checkFreeDeliveryAreas } from '../../assets/js/delivery'
import router from '../../router'

function countOrderDishItemPrice(d, dish, force_normal_price) {
    let sum = d.for_points ? 0.0 : d.forced_promo_price > 0 ? d.forced_promo_price : force_normal_price ? dish.price : dish.get_price
    d.order_dish_addons_attributes.forEach(dishAddon => {
        sum += dish.addons.find(a => a.id == dishAddon.addon_id).price * dishAddon.amount
    })
    return (sum)
}

function countOrderDishPrice(d, dish, force_normal_price) {
    // Block more than 1 dish
    // return countOrderDishItemPrice(d, dish, force_normal_price) * d.amount
    return countOrderDishItemPrice(d, dish, force_normal_price) * 1
}

function formattedAddons(d, dish, key) {
    return d.order_dish_addons_attributes.map((da, dai) => {
        let addon = dish.addons.find(a => a.id == da.addon_id)
        return {
            key: key + 'addon' + dai,
            name: addon.name,
            name_en: addon.name_en,
            name_ru: addon.name_ru,
            amount: da.amount,
            item_price: addon.price,
            price: addon.price * da.amount
        }
    })
}

function getI18nVersion(dish, version, lang) {
    const vidx = dish.version.findIndex(v => v === version)
    const versionsLang = dish["version_" + lang]
    if (versionsLang) {
        const t = versionsLang[vidx]
        if (t) return t
    }
    return version
}

function formattedOrder(dishes, dishPromocode, freeFood, menu, force_normal_price) {
    const formattedDishes = dishes.map((d, di) => {
        let dish = menu.find(m => m.id == d.dish_id)
        let key = 'dish' + di
        let additionalCategoryName = dish.category.display_category_name ? dish.category.name : null
        return {
            key: key,
            name: dish.name,
            name_en: dish.name_en,
            name_ru: dish.name_ru,
            id: dish.id,
            // Block more than 1 dish
            // amount: d.amount,
            amount: 1,
            version: d.version,
            version_en: getI18nVersion(dish, d.version, 'en'),
            version_ru: getI18nVersion(dish, d.version, 'ru'),
            comment: d.comment,
            item_old_price: dish.price,
            is_promo_price: d.for_points ? false : d.forced_promo_price > 0 ? true : force_normal_price ? false : dish.is_promo_price,
            was_promo_price: dish.is_promo_price,
            item_price: d.for_points ? 0.0 : d.forced_promo_price > 0 ? d.forced_promo_price : force_normal_price ? dish.price : dish.get_price,
            item_price_with_addons: countOrderDishItemPrice(d, dish, force_normal_price),
            price: countOrderDishPrice(d, dish, force_normal_price),
            points_price: d.for_points ? dish.points_price : 0.0,
            addons: formattedAddons(d, dish, key),
            category_name: additionalCategoryName,
            category_name_en: additionalCategoryName ? dish.category.name_en : null,
            category_name_ru: additionalCategoryName ? dish.category.name_ru : null,
            is_add_more: d.add_more_id != undefined,
        }
    })

    if (dishPromocode !== false) {
        formattedDishes.push(dishPromocode)
    }

    freeFood.forEach((item, index) => {
        formattedDishes.push({
            key: 'dishfreefood' + index,
            name: item,
            amount: 1,
            item_price: 0.0,
            item_price_with_addons: 0.0,
            price: 0.0,
            addons: [],
        })
    })

    return formattedDishes
}

function discountAddon(order) {
    if (order.discount_kind !== 'addon') {
        return false
    }

    return {
        key: 'dishpromocode',
        name: order.discount_value,
        amount: 1,
        item_price: 0.0,
        item_price_with_addons: 0.0,
        price: 0.0,
        addons: [],
    }
}

const getDefaultState = () => {
    return {
        order: {
            address: null,
            apartment: null,
            floor: null,
            staircase: null,
            latitude: null,
            longitude: null,
            name: null,
            email: null,
            tel: null,
            nip: null,
            payment_method: null,
            promocode: null,
            discount_kind: null,
            discount_value: null,
            comment: null,
            order_type: 'online',
            delivery_distance: 0.0,
            delivery_price: 0.0,
            delivery_possible: true,
            delivery_error: null,
            delivery_free: null,
            delivery_when: null,
            force_normal_price: false,
            forced_delivery_price: -1,
            free_food: [],
            free_addons: [],
            table_id: null,
            add_service: false,
            extra_items: [
                {name: "Opłata za opakowanie", name_en: "Packaging fee", name_ru: "Плата за упаковку", price: 0.50, amount: 1, vat: 'B', type: 'plastic'},
            ],
        },
        orderDishes: [],
        menuSlug: null,
    }
}

const state = getDefaultState()

const getters = {
    order: state => state.order,
    orderDishes: state => state.orderDishes,
    menuSlug: state => state.menuSlug,
    cart: (state, getters, rootState, rootGetters) => {
        let special = rootGetters.specialMenus ? rootGetters.specialMenus.find(m => m.slug == getters.menuSlug) : undefined
        let menu = getters.menuSlug == 'normal' || special == undefined ? rootGetters.menu : special.dishes
        return {
            ...state.order,
            order_dishes_attributes: state.orderDishes,
            formatted_order: formattedOrder(state.orderDishes, discountAddon(state.order), state.order.free_food, menu, getters.order.force_normal_price),
            price: getters.cartPrice[0],
            cart_price: getters.cartPrice[0],
            points_price: getters.cartPrice[1],
            discount_amount: getters.cartDiscount,
            togo_discount_amount: getters.cartTogoDiscount,
            total_price: getters.cartPrice[0] + getters.cartDiscount + getters.cartTogoDiscount + state.order.delivery_price + getters.extraItemsPrice,
            delivery_possible: state.order.delivery_possible,
            delivery_error: state.order.delivery_error,
            delivery_free: state.order.delivery_free,
        }
    },
    selectedTimedCategory: (state, getters, rootState, rootGetters) => {
        let special = rootGetters.specialMenus ? rootGetters.specialMenus.find(m => m.slug == getters.menuSlug) : undefined
        let menu = getters.menuSlug == 'normal' || special == undefined ? rootGetters.menu : special.dishes
        return state.orderDishes.filter(orderDish => {
            let dish = menu.find(m => m.id == orderDish.dish_id)
            return dish.category.is_timed === true
        }).length > 0
    },
    cartPrice: (state, getters, rootState, rootGetters) => {
        let special = rootGetters.specialMenus ? rootGetters.specialMenus.find(m => m.slug == getters.menuSlug) : undefined
        let menu = getters.menuSlug == 'normal' || special == undefined ? rootGetters.menu : special.dishes
        let price = 0.0
        let pointsPrice = 0
        state.orderDishes.forEach(orderDish => {
            let dish = menu.find(m => m.id == orderDish.dish_id)
            let dishPrice = orderDish.for_points ? 0.0 : orderDish.forced_promo_price > 0.0 ? orderDish.forced_promo_price : getters.order.force_normal_price ? dish.price : dish.get_price
            orderDish.order_dish_addons_attributes.forEach(dishAddon => {
                    dishPrice += dish.addons.find(a => a.id == dishAddon.addon_id).price * dishAddon.amount
                })
                // Block more than 1 dish
                // price += dishPrice * orderDish.amount
            price += dishPrice * 1
            if (orderDish.for_points) {
                pointsPrice += dish.points_price
            }
        })
        return [price, pointsPrice]
    },
    extraItemsPrice: (state) => {
        let price = 0.0
        state.order.extra_items.forEach(item => {
            price += item.amount * item.price
        })
        return price
    },
    // cartPointsPrice: ()
    cartDiscount: (state, getters) => {
        let discount_amount = 0.0
        if (state.order.discount_kind === 'discount') {
            discount_amount -= getters.cartPrice[0] * state.order.discount_value * 1.0 / 100
        }
        if (state.order.discount_kind === 'amount') {
            discount_amount -= state.order.discount_value * 100.0 / 100
        }
        return discount_amount
    },
    cartTogoDiscount: (state, getters) => {
        let discount_amount = 0.0
        if (state.order.order_type == 'to_go') {
            discount_amount -= (getters.cartPrice[0] + getters.cartDiscount) * 0.1
        }
        return discount_amount
    },
    containPromoItems: (state, getters) => {
        return getters.cart.formatted_order.find(d => d.was_promo_price) != undefined
    },
    canOrderToday: (state, getters, rootState, rootGetters) => {
        const current_user = rootState.user.user
        return (!rootGetters.settings.is_closed && rootGetters.settings.open_now) || (current_user && current_user.is_staff)
    },
    canCreateOrder: (state) => {
        let errors = []

        let mustHave = state.order.order_type == 'online' ? ['address', 'name', 'email', 'tel', 'payment_method'] : ['name', 'email', 'tel', 'payment_method']
        if (mustHave.map(k => state.order[k]).findIndex(a => a == null || a == '' || a == undefined) > -1) {
            errors.push("emptyFields")
        }

        let delivery_possible = state.order.order_type == 'online' ? state.order.delivery_possible : true
        if (!delivery_possible) {
            errors.push("deliveryNotPossible")
        }
        if (state.menuSlug != 'normal' && state.order.order_type != 'in_restaurant') {
            if (state.order.delivery_when == null) {
                errors.push("dateNotSelected")
            }
        }

        return [errors.length <= 0, errors]
    },
}

const mutations = {
    updateOrder(state, payload) {
        state.order = {...state.order, ...payload }
    },
    addDish(state, payload) {
        state.menuSlug = payload.menuSlug
        state.order.table_id = payload.table_id
        state.orderDishes.push(payload.dish)
    },
    removeDish(state, payload) {
        state.orderDishes.splice(payload, 1)
    },
    resetOrder(state) {
        Object.assign(state, getDefaultState())
    },
}

const actions = {
    setOrder({ commit }, payload) {
        commit('updateOrder', payload)
    },
    addDish({ commit, rootState }, payload) {
        const menuSlug = rootState.order.menuSlug
        const tableId = rootState.order.order.table_id
        if (menuSlug != null && menuSlug != payload.menuSlug && confirm(router.app.$t('menu.differentMenuError'))) {
            commit('resetOrder')
            commit('addDish', payload)
        } else if (tableId != null && tableId != payload.table_id && confirm('Zmieniono rodzaj zamówienia zanim zapisano nowe dania. Jeżeli chcesz kontynuować zresetują się wcześniejsze zmiany.')) {
            commit('resetOrder')
            commit('addDish', payload)
        } else {
            commit('addDish', payload)
        }
    },
    removeDish({ commit }, payload) {
        commit('removeDish', payload)
    },
    async setAddress({ commit, getters, rootState, rootGetters }, payload) {
        const distance = await countDeliveryDistance({
            order_type: rootState.order.order.order_type,
            ...payload,
        }, rootState.settings)
        let specialMenuMinPrice = 0
        if (getters.menuSlug != 'normal' && rootGetters.specialMenus) {
            let special = rootGetters.specialMenus.find(m => m.slug == getters.menuSlug)
            if (special != undefined) {
                specialMenuMinPrice = special.min_order_price
            }
        }
        const current_user = rootState.user.user
        let price = countDeliveryPrice(distance, rootState.deliveries, getters.cartPrice[0], specialMenuMinPrice, current_user && current_user.is_admin)
        // console.log('setAddress', price)
        
        // Check free delivery areas
        if (getters.menuSlug === 'normal' && (price[0] > 0 || !price[1]) && rootState.freeDeliveryAreas.length > 0) {
            price = await checkFreeDeliveryAreas(price, {
                order_type: rootState.order.order.order_type,
                price: getters.cartPrice[0],
                ...payload,
            }, rootState.freeDeliveryAreas, current_user && current_user.is_admin)
        }

        // console.log('setAddress', price)

        commit('updateOrder', {
            ...payload,
            delivery_distance: distance,
            delivery_price: getters.order.forced_delivery_price >= 0 ? getters.order.forced_delivery_price : price[0],
            delivery_possible: price[1],
            delivery_error: price[1] ? null : price[2],
            delivery_free: price[1] && price[0] > 0 && price[2] != undefined ? price[2] : null,
        })
    },
    resetOrder({ commit }) {
        commit('resetOrder')
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}