const state = {
    img: null
}

const getters = {
    menuGalleryImg: state => state.img,
}

const mutations = {
    setMenuGalleryImg(state, payload) {
        state.img = payload
    },
    resetMenuGalleryImg(state) {
        state.img = null
    },
}

const actions = {
    setMenuGalleryImg({ commit }, payload) {
        commit('setMenuGalleryImg', payload)
    },
    resetMenuGalleryImg({ commit }) {
        commit('resetMenuGalleryImg')
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}