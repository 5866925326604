<template>
	<li
		class="locale-changer"
		@mouseover='openLangMenu()'
		@mouseout='closeLangMenu()'
	>
		<button class="locale-changer__trigger">
			<span>
				<img :src="require(`../../assets/img/flags/${$i18n.locale}.png`)" />
			</span>
			<i v-if='langMenuOpen' class="fas fa-chevron-up is-visible"></i>
			<i v-else class="fas fa-chevron-down is-visible"></i>
		</button>
		<ul
			class="locale-changer__menu"
			:class="{'is-open': langMenuOpen}"
			@click='closeLangMenu()'
			@mouseover='onLangMenuHover()'
			@mouseout='offLangMenuHover()'
		>
			<li v-for='(lang, i) in langs' :key='`lang${i}`'>
				<button class="locale-changer__menu__button" :class="{'is-active': $i18n.locale === lang}" @click='selectLang(lang)'>
					<span>
						<img :src="require(`../../assets/img/flags/${lang}.png`)" />
					</span>
				</button>
			</li>
		</ul>
	</li>
</template>

<script>
export default {
	data: () => ({
		langs: [ 'en', 'pl', 'ru' ],
		langMenuOpen: document.documentElement.clientWidth < 1100,
		langMenuHover: false,
	}),
	methods: {
		openLangMenu () {
			setTimeout(() => {
				if (document.documentElement.clientWidth >= 1100) {
					this.langMenuOpen = true
				}
			}, 100)
		},
		closeLangMenu () {
			setTimeout(() => {
				if (document.documentElement.clientWidth >= 1100 && !this.langMenuHover) {
					this.langMenuOpen = false
				}
			}, 100)
		},
		onLangMenuHover () {
			this.langMenuHover = true
		},
		offLangMenuHover () {
			this.langMenuHover = false
			this.closeLangMenu()
		},
		selectLang (lang) {
			this.$i18n.locale = lang
			localStorage.setItem('lang', lang)
		}
	}
}
</script>

<style lang="scss">
@import '../../assets/css/breakpoints.scss';

.locale-changer {
	position: relative;

	&__trigger {
		@include bp-mobilenav {
			display: none !important;
		}

		background-color: transparent;
		border-color: transparent;
		font-weight: bold;
		font-size: 16px;
		letter-spacing: 0.4px;
		text-transform: uppercase;

		i {
			color: var(--white);
			margin-left: 5px;
		}
	}

	&__menu {
		display: none;

		position: absolute;
		bottom: 0;
		left: 50%;
		align-items: center;
		justify-content: center;
		transform: translate(-50%, 99%);
		// background-color: var(--black);

		@include bp-mobilenav {
			display: flex !important;
			position: relative;
			transform: translate(-50%, 0);
			height: 46px;
		}

		&.is-open {
			display: flex;
		}

		> li {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 2.5px;
		}

		&__button {
			background-color: transparent;
			border-color: transparent;
			padding: 2.5px;
			cursor: pointer;

			&.is-active, &:hover {
				background-color: var(--black);

				@include bp-mobilenav {
					background-color: var(--red);
				}
			}

			img {
				display: block;

				@include bp-mobilenav {
					box-shadow: 0px 0px 40px 5px black;
				}
			}
		}
	}
}
</style>