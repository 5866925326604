import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import auth from './auth'
import account from './account'
import admin from './admin'

const MainPage = () =>
    import ('../components/layout/MainPage')
const Menu = () =>
    import ('../components/menu/Menu')
const Cart = () =>
    import ('../components/menu/Cart')
const ActiveOrderMap = () =>
    import ('../components/staff/orders/ActiveOrderMap')
const StaffOrders = () =>
    import ('../components/staff/orders/StaffOrders')
const StaffOrder = () =>
    import ('../components/staff/orders/StaffOrder')
const Orders = () =>
    import ('../components/orders/Orders')
const Order = () =>
    import ('../components/orders/Order')
const Terms = () =>
    import ('../components/others/Terms')
const PrivacyPolicy = () =>
    import ('../components/others/PrivacyPolicy')
const DeliveryPrice = () =>
    import ('../components/others/DeliveryPrice')
const NoGluten = () =>
    import ('../components/others/NoGluten')
const Catering = () =>
    import ('../components/others/Catering')
const Contact = () =>
    import ('../components/others/Contact')
const VirtualWalk = () =>
    import ('../components/others/VirtualWalk')
const HappyHours = () =>
    import ('../components/others/HappyHours')

Vue.use(Router)

const router = new Router({
    routes: [{
            path: '/',
            name: 'MainPage',
            component: MainPage
        },
        auth,
        admin,
        account,
        {
            path: '/menu',
            name: 'Menu',
            component: Menu
        },
        {
            path: '/menu/:slug',
            name: 'SpecialMenu',
            component: Menu,
        },
        {
            path: '/karta',
            name: 'Karta',
            component: Menu,
            meta: { menuCardMode: true }
        },
        {
            path: '/twoje-zamowienie',
            name: 'Cart',
            component: Cart
        },
        {
            path: '/zamowienia/',
            name: 'Orders',
            component: Orders
        },
        {
            path: '/zamowienie/:id',
            name: 'Order',
            component: Order
        },
        {
            path: '/praca/zamowienia/',
            name: 'StaffOrders',
            component: StaffOrders,
            meta: { onlyStaff: true, staffOrders: 'queued' }
        },
        {
            path: '/praca/zamowienia/mapa-zamowien',
            name: 'ActiveOrderMap',
            component: ActiveOrderMap,
            meta: { onlyStaff: true, allowDriver: true }
        },
        {
            path: '/praca/zamowienia/w-toku',
            name: 'StaffOrdersActive',
            component: StaffOrders,
            meta: { onlyStaff: true, allowDriver: true, staffOrders: 'active' }
        },
        {
            path: '/praca/zamowienia/zakonczone',
            name: 'StaffOrdersEnded',
            component: StaffOrders,
            meta: { onlyStaff: true, allowDriver: true, staffOrders: 'ended' }
        },
        {
            path: '/praca/zamowienia/przyszle',
            name: 'StaffOrdersFuture',
            component: StaffOrders,
            meta: { onlyStaff: true, staffOrders: 'future' }
        },
        {
            path: '/praca/zamowienia/gotowka-oddana',
            name: 'StaffOrdersCashReturned',
            component: StaffOrders,
            meta: { onlyStaff: true, staffOrders: 'cash_returned' }
        },
        {
            path: '/praca/zamowienia/kolejka-paragonow',
            name: 'StaffOrdersToPrint',
            component: StaffOrders,
            meta: { onlyStaff: true, staffOrders: 'toPrint' }
        },
        {
            path: '/praca/zamowienie/:id',
            name: 'StaffOrder',
            component: StaffOrder,
            meta: { onlyStaff: true, allowDriver: true }
        },
        {
            path: '/regulamin',
            name: 'Terms',
            component: Terms
        },
        {
            path: '/polityka_prywatnosci',
            name: 'PrivacyPolicy',
            component: PrivacyPolicy
        },
        {
            path: '/koszty-dostawy',
            name: 'DeliveryPrice',
            component: DeliveryPrice
        },
        {
            path: '/sushi-bez-glutenu',
            name: 'NoGluten',
            component: NoGluten
        },
        {
            path: '/catering',
            name: 'Catering',
            component: Catering
        },
        {
            path: '/contact',
            name: 'Contact',
            component: Contact
        },
        {
            path: '/wirtualny-spacer',
            name: 'VirtualWalk',
            component: VirtualWalk
        },
        {
            path: '/happy-hours',
            name: 'HappyHours',
            component: HappyHours
        },
        {
            path: '/ocen-nas-w-google',
            beforeEnter() {
                window.location = 'https://g.page/Akari-Sushi-Warszawa/review?gm'
            }
        },
        {
            path: '*',
            redirect: '/'
        }
    ],
    mode: 'history',
    linkActiveClass: 'is-active'
})

router.beforeEach((to, from, next) => {
    if (from.name != null) {
        const user = store.getters['user/current_user']

        // If user already logged in and try to go to onlyGuest route (mainly auth related)
        if (to.meta.onlyGuest && user !== null) {
            next('/')
            return
        }
        // If guest try to go to authorize route
        if ((to.meta.authRequired || to.meta.onlyStaff) && user === null) {
            router.app.$toastr.i(router.app.$t('messages.mustLogin'))
            next({ name: 'Login' })
            return
        }

        // If user try to go to onlyStaff route
        if (to.meta.onlyStaff && !user.is_staff) {
            let denied = true
            if (to.meta.allowDriver && user.is_driver) {
                denied = false
            }
            if (denied) {
                router.app.$toastr.e(router.app.$t('messages.accessDenied'))
                next(false)
                return
            }
        }

        // If moderator try to go to onlyAdmin route
        if ((to.meta.onlyAdmin && !user.is_admin) || (to.meta.onlySuperadmin && !user.is_superadmin)) {
            router.app.$toastr.e(router.app.$t('messages.onlyAdmin'))
            next(false)
            return
        }

        // Turn off mobileMenu
        store.dispatch('hideMobileMenu')
    }

    next()
})

export default router