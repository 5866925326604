<template>
    <webp-image name="small-logo" ext="png" alt="Wczytywanie" ownClass="spinner" :ownStyle='{width: size + "px", height: size + "px"}' :lazyImg="false" />
</template>

<script>
export default {
    props: {
        size: {
            type: Number,
            default: 50,
        }
    }
}
</script>

<style lang="scss">
.spinner {
    -webkit-animation:spin 2s ease-in-out infinite;
    -moz-animation:spin 2s ease-in-out infinite;
    animation:spin 2s ease-in-out infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
</style>