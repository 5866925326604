import { defaultLocale } from '../i18n'

export function trans (obj, key, arrIdx = null) {
	const lang = localStorage.getItem('lang') || defaultLocale
	const keyLang = key + '_' + lang
	let t = obj[keyLang]
	if (!t || t === "null") {
		t = obj[key]
	}

	if (arrIdx !== null && t instanceof Array) {
		if (t.length <= arrIdx) {
			return obj[key][arrIdx]
		}
		return t[arrIdx]
	}

	return t
}
