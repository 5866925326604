<template>
	<li class="staff-orders-button" :class='{"is-blinking-red-lighter": new_orders > 0}'>
		<router-link :to='{name: current_user.is_driver ? "StaffOrdersActive" : "StaffOrders"}'>
			<span>{{ $t('nav.staffOrders') }}</span>
		</router-link>
	</li>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { getStaffOrdersStatus } from '../../../api/api'
import {Howl} from 'howler';

export default {
	data: () => ({
		new_orders: 0,
		interval: null,
		bellInterval: null,
		bell: null,
	}),
	computed: {
		...mapGetters({
			current_user: 'user/current_user',
		})
	},
	methods: {
		...mapActions({
			setNewQueued: 'setNewQueued',
			setUberErrors: 'setUberErrors',
			setWoltAutoaccepted: 'setWoltAutoaccepted',
			setCanceledOrders: 'setCanceledOrders',
		}),
		checkNewOrders () {
			getStaffOrdersStatus()
				.then(res => {
					this.new_orders = res.data.new_orders
					if (res.data.uber_errors !== undefined) {
						this.setUberErrors(res.data.uber_errors)
					}
					if (res.data.autoaccepted !== undefined) {
						this.setWoltAutoaccepted(res.data.autoaccepted)
					}
					if (res.data.canceled !== undefined) {
						this.setCanceledOrders(res.data.canceled)
					}
				})
				.catch(() => {
					console.log('error')
				})
		},
		playBell () {
			if (this.bell) {
				this.bell.play()
			}
		},
		startWorker () {
			this.interval = setInterval(this.checkNewOrders, process.env.VUE_APP_WORKER_INTERVAL)
		},
		stopWorker () {
			clearInterval(this.interval)
			this.interval = null
		},
		startBellWorker () {
			this.playBell()
			this.bellInterval = setInterval(this.playBell, 90000)
		},
		stopBellWorker () {
			clearInterval(this.bellInterval)
			this.bellInterval = null
		},
	},
	mounted () {
		if (!this.current_user.is_driver) {
			this.checkNewOrders()
			this.startWorker()
		}

		// Bell sound
		this.bell = new Howl({
			src: [process.env.VUE_APP_FRONT_URL + '/bell.mp3'],
			volume: 1,
		})
	},
	watch: {
		new_orders (newNumber, oldNumber) {
			if (newNumber != oldNumber && newNumber > 0) {
				this.setNewQueued(newNumber)

				if (newNumber > oldNumber) {
					this.startBellWorker()
				}
			}

			if (newNumber != oldNumber && newNumber <= 0) {
				this.stopBellWorker()
				this.setNewQueued(-1)
			}
		}
	},
	beforeDestroy () {
		this.stopWorker()
		this.stopBellWorker()
	}
}
</script>

<style lang="scss">
.staff-orders-button {
	padding: 5px 10px;
}
</style>